<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header pt-10 pb-10">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ข้อมูลร้านค้า / Business Information
      </h3>
      <div class="card-toolbar"></div>
    </div>

    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table class="table table-hover table-row-dashed gy-7 gs-7">
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>Business ID</th>
              <th>ชื่อกิจการ</th>
              <th>เบอร์โทรศัพท์</th>
              <th>Digital Address</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.company_business_id }}</td>
              <td>{{ item.company_name }}</td>
              <td>{{ item.company_tel }}</td>
              <td>{{ item.digital_address }}</td>
              <td>
                <button
                  style="padding: 5px; width: 80px; cursor: default"
                  :class="item.status ? class1 : class2"
                >
                  {{ item.status ? "Active" : "Inactive" }}
                </button>
              </td>

              <td></td>
              <td>
                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";

export default {
  setup() {
    document.title = "BELUCA | ข้อมูลร้านค้า";
  },
  components: { Pagination, Loader },
  data: () => ({
    userId: "",
    user: [],
    classRole: "badge badge-lg badge-light-success fw-bolder fs-6",

    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    class1: "btn btn-sm btn-light-success fw-boldest",
    class2: "btn btn-sm btn-light fw-boldest",
  }),
  created() {
    this.getCompany();
    // this.getAllUser();
  },
  mounted() {
    this.checkRole();
  },
  methods: {
    checkRole() {
      const role = Decode.decode(localStorage.getItem("role")).replace(
        /^"(.*)"$/,
        "$1"
      );
      const id = localStorage.getItem("companyId");

      if (role !== "SYSTEM ADMIN" && role !== "ROOT") {
        this.$router.push({
          path: "/setting/shop-info-detail",
          query: {
            id: id,
          },
        });
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    async getCompany() {
      this.loading = true;
      const response = await userApi.company.getAll();

      if ((response.response_status = "SUCCESS")) {
        this.dataItemsShow = response.data;
        this.tableItems = response.data;
        this.allData = response.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    goToEdit(id) {
      this.$router.push({
        path: "/setting/shop-info-detail",
        query: {
          id: id,
        },
      });
      // if (this.isEdit) {
      //   this.updateCompany();
      //   this.isEdit = false;
      // } else {
      //   console.log(2);
      //   this.isEdit = true;
      // }
    },
  },
};
</script>
